import React, { useState, useContext, useEffect } from 'react';
import Logo from '../resources/LOGO_001.webp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ScrollContext from './ScrollContext';

function Navybar({ size }) {
  const [selectedItem, setSelectedItem] = useState("About us");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { eventsRef, socialRef, collectionsRef, scuppDetailsRef } = useContext(ScrollContext);

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
    switch (itemName) {
      case "Events":
        eventsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Social":
        socialRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Collections":
        collectionsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "ScuppDetails":
        scuppDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => {
    if (isSidebarOpen) {
      setIsVisible(false);
      setTimeout(() => setIsSidebarOpen(false), 500);
    } else {
      setIsSidebarOpen(true);
      setTimeout(() => setIsVisible(true), 0);
    }
  };

  useEffect(() => {
    if (!isSidebarOpen) {
      setIsVisible(false);
    }
  }, [isSidebarOpen]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "20vh",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            style={{
              display: "flex",
              width: "200px",
              margin: "0 0 0 5%",
              objectFit: "cover",
            }}
            src={Logo}
            alt="Logo"
          />
          {size === "mobile-large" || size === "tablet" ? (
            <div style={{ display: "flex", justifyContent: "end", margin: "5%" }}>
              <MenuIcon sx={{ color: "white", fontSize: "40px" }} onClick={toggleSidebar} />
            </div>
          ) : (
            <>
              <div style={{ display: "flex", minWidth: "50vw", flexDirection: "row", justifyContent: "space-evenly", marginTop: "15px" }}>
                <h1
                  onClick={() => handleItemClick("Events")}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "2em",
                    borderBottom: selectedItem === "Events" ? "2px solid #FF6F4D" : "none",
                    paddingBottom: "5px",
                    cursor: "pointer"
                  }}
                >
                  Experimente
                </h1>
                <h1
                  onClick={() => handleItemClick("Social")}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "2em",
                    borderBottom: selectedItem === "Social" ? "2px solid #FF6F4D" : "none",
                    paddingBottom: "5px",
                    cursor: "pointer"
                  }}
                >
                  Socialize
                </h1>
                <h1
                  onClick={() => handleItemClick("Collections")}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "2em",
                    borderBottom: selectedItem === "Collections" ? "2px solid #FF6F4D" : "none",
                    paddingBottom: "5px",
                    cursor: "pointer"
                  }}
                >
                  Match making
                </h1>
                <h1
                  onClick={() => handleItemClick("ScuppDetails")}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "2em",
                    borderBottom: selectedItem === "ScuppDetails" ? "2px solid #FF6F4D" : "none",
                    paddingBottom: "5px",
                    cursor: "pointer"
                  }}
                >
                  Sobre nós
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "12vw",
                  height: "6vh",
                  background: "#FF6F4D",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  color: "white",
                  fontSize: "1em",
                  margin: "0 2%",
                }}
              >
                <h3 style={{ fontSize: size === "desktop-small" ? "1em" : "1.5em", fontWeight: "bold" }}>Contact us</h3>
              </div>
            </>
          )}
        </div>
      </div>
      {isSidebarOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            backgroundColor: "#333",
            width: isVisible ? (size === "tablet" ? "30%" : size === "mobile-large" ? "40%" : "0px") : "0",
            zIndex: 1000,
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderRadius: "0 0 0 24px",
            transition: "width 0.5s",
            overflow: "hidden",
          }}
        >
          <CloseIcon sx={{ color: "white", fontSize: "30px", alignSelf: "flex-end", cursor: "pointer" }} onClick={toggleSidebar} />
          <h1
            onClick={() => handleItemClick("Events")}
            style={{
              color: "white",
              textDecoration: "none",
              fontSize: "1.5em",
              paddingBottom: "10px",
              cursor: "pointer"
            }}
          >
            Experimente
          </h1>
          <h1
            onClick={() => handleItemClick("Social")}
            style={{
              color: "white",
              textDecoration: "none",
              fontSize: "1.5em",
              paddingBottom: "10px",
              cursor: "pointer"
            }}
          >
            Socialize
          </h1>
          <h1
            onClick={() => handleItemClick("Collections")}
            style={{
              color: "white",
              textDecoration: "none",
              fontSize: "1.5em",
              paddingBottom: "10px",
              cursor: "pointer"
            }}
          >
            Match making
          </h1>
          <h1
            onClick={() => handleItemClick("ScuppDetails")}
            style={{
              color: "white",
              textDecoration: "none",
              fontSize: "1.5em",
              paddingBottom: "10px",
              cursor: "pointer"
            }}
          >
            Sobre nós
          </h1>
        </div>
      )}
    </>
  );
}

export default Navybar;
