import "../App.css";
import { useInView } from "react-intersection-observer";
import AddReactionIcon from '@mui/icons-material/AddReaction';


function Social({size, posc , user, coment, emoji, img }) {
  const { ref: ref, inView: isVisible } = useInView({
    rootMargin: "0px"
  });
  return (
    <>
    <div style={{display: "flex", flexDirection: "column", width: "100%", height: "auto", alignItems:  posc == 2 || posc == 3 ? "flex-end" : "flex-start", }}>
    <div ref={ref} style={{display: "flex", position: "relative", width: "60%", height: "50%", justifySelf: "flex-end", justifyContent: "flex-end", alignItems: "center", margin: size === "mobile-large" ? "0 5%" : "0 5%", top: posc == 1 || posc == 4 ? posc == 4 ?  "-5%" : "15%" : "",transform: isVisible ? "translateX(0)": "translateX(+100%)", opacity: isVisible ? 1 : 0, transition: "all 1s",}}>
        <img
            style={{
                display: "flex",
                width: "15%",
                objectFit: "cover",
                }}
                src={img}     
        /> 
        <div style={{display: "flex",flexDirection: "column", width: "75%", height: "100%",justifyContent: "center", margin: "2%"}}>
            <div style={{fontSize: "1em", color: "white", fontWeight: "bold", }}>{user}</div>
            <div style={{fontSize: "0.8em", color: "#C9C9C9",}}>{coment}</div>
        </div>
        <AddReactionIcon sx={{color: "white"}} style={{display: "flex", width: "10%",justifyContent: "center", alignItems: "center",}}></AddReactionIcon>           
    </div>
    </div>
    </>
  );
}

export default Social;
