import './App.css';
import React, { useState, useEffect } from 'react';
import MainPage from './components/MainPage';
import { useMediaQuery } from 'react-responsive';

function App() {

  const [screenClass, setScreenClass] = useState('');

  const isMobileLarge = useMediaQuery({ query: '(min-width: 481px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 769px)' });
  const isDesktopSmall = useMediaQuery({ query: '(min-width: 1025px)' });
  const isDesktopLarge = useMediaQuery({ query: '(min-width: 1201px)' });

  useEffect(() => {
    if (isDesktopLarge) return setScreenClass('desktop-large');
    if (isDesktopSmall) return setScreenClass('desktop-small');
    if (isTablet) return setScreenClass('tablet');
    if (isMobileLarge) return setScreenClass('mobile-large');
    if (screenClass === undefined || screenClass === "") return setScreenClass('mobile-large');
  }, [isDesktopLarge, isDesktopSmall, isTablet, isMobileLarge]);

  return (
    <MainPage screenClass={screenClass} />
  );
}

export default App;
