import "../App.css";
import React, { useContext } from 'react';
import ScrollContext from './ScrollContext';
import logo from "../resources/LOGO_003.webp";
import PhoneIcon from '@mui/icons-material/Phone';



function ScuppDetails({ size }) {
  const { scuppDetailsRef } = useContext(ScrollContext);
  return (
    <>
    <div ref={scuppDetailsRef} style={{width: "100%", height: size === "mobile-large" ? "130vh" : "100vh", background: "linear-gradient(to right, black, black 25%, #0D292D 100%)", justifyContent: "center", alignItems: "center"}}>
      <div style={{display: "flex", flexDirection: size === "mobile-large" ? "column-reverse" : "row" , width: "100%", height: "100%", justifyContent: "center", alignItems: "center", alignSelf: "center",}}>
        <div style={{display: "flex",  height: "60%", width: size === "mobile-large" ? "100%" : "60%", justifyContent: "center"}}>

          <div style={{display: "flex", flexDirection: "column", height: "100%", width: "80%", justifyContent: size === "mobile-large" ? "start" : size === "tablet" ? "start" : "center"}}>
            <h1 style={{color: "white", textTransform: "uppercase", fontWeight: "bold", fontSize: size == "mobile-large" ? "50px" : size === "tablet" ? "50px" : "72px", }}>Descubra como nosso produto pode alavancar seu evento</h1>
            <div style={{fontSize: size == "mobile-large" ? "14px" : size === "tablet" ? "14px" : "18px", color: "#C9C9C9",lineHeight: "4vh"}}>Quer entender mais os padrões de consumo e preferências de seu público, centralizados em uma única solução? Entre em contato conosco e entenda como podemos te ajudar de uma forma inovadora e consistente no vasto cenário de eventos e ambientes de entretenimento</div>     
            <div style={{display: "flex", flexDirection: size === "mobile-large" ? "column" : size === "tablet" ? "column" : "row" , justifyContent: "space-between", marginTop: "5vh"}}>
              <div style={{display: "flex", flexDirection: "row" , width: "100%", justifyContent: "start", alignItems: size === "mobile-large" ? "start" : "center", textAlign: "center"  }}>
              <a href="https://www.linkedin.com/company/scuppapp/" target="_blank" rel="noopener noreferrer">
                <svg width="60" height="60" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1322_16920)">
                    <rect width="92" height="92" rx="16" fill="#474747"/>
                    <rect x="13" y="13" width="69" height="71" fill="#02D8F4"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M49.8215 41.3192V41.1847C49.7941 41.2298 49.756 41.2749 49.7322 41.3192H49.8215Z" fill="#0F0F0F"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V92H92V0H0ZM28.6154 76.4312H14.9659V35.3656H28.6154V76.4312ZM21.7907 29.7607H21.7005C17.1217 29.7607 14.1538 26.6062 14.1538 22.6633C14.1538 18.6339 17.2084 15.5688 21.88 15.5688C26.5526 15.5688 29.424 18.6339 29.5134 22.6633C29.5134 26.6062 26.5526 29.7607 21.7907 29.7607ZM77.8462 76.4312H64.193V54.461C64.193 48.9428 62.2203 45.176 57.2788 45.176C53.505 45.176 51.2624 47.7122 50.2745 50.1689C49.9154 51.0465 49.8215 52.2663 49.8215 53.4969V76.4314H36.1657C36.1657 76.4314 36.3487 39.2183 36.1657 35.3658H49.8215V41.1849C51.6359 38.3931 54.8737 34.4017 62.1265 34.4017C71.1116 34.4017 77.8462 40.2695 77.8462 52.8822V76.4312Z" fill="#0F0F0F"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1322_16920">
                      <rect width="92" height="92" rx="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a href="https://www.instagram.com/scuppapp" target="_blank" rel="noopener noreferrer">
                <svg style={{marginLeft: "3vw"}} width="60" height="60" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="92" height="92" rx="16" fill="#0F0F0F"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M49.8215 41.3192V41.1847C49.7941 41.2298 49.756 41.2749 49.7322 41.3192H49.8215Z" fill="#0F0F0F"/>
                  <path d="M68.5837 86.9562L22.4969 87C12.3578 87.0092 4.05531 78.7228 4.04379 68.5837L4.00001 22.4969C3.99079 12.3578 12.2772 4.05531 22.4163 4.04379L68.5031 4.00001C78.6422 3.99079 86.9447 12.2772 86.9562 22.4163L87 68.5031C87.0115 78.6445 78.7228 86.947 68.5837 86.9562Z" fill="#0F0F0F"/>
                  <path d="M45.5 61C36.9551 61 30 54.0471 30 45.5C30 36.9529 36.9551 30 45.5 30C54.0449 30 61 36.9529 61 45.5C61 54.0471 54.0449 61 45.5 61ZM45.5 34.4286C39.3952 34.4286 34.4286 39.3952 34.4286 45.5C34.4286 51.6048 39.3952 56.5714 45.5 56.5714C51.6048 56.5714 56.5714 51.6048 56.5714 45.5C56.5714 39.3952 51.6048 34.4286 45.5 34.4286Z" fill="#D504A7"/>
                  <path d="M63 31C64.6569 31 66 29.6569 66 28C66 26.3431 64.6569 25 63 25C61.3431 25 60 26.3431 60 28C60 29.6569 61.3431 31 63 31Z" fill="#D504A7"/>
                  <path d="M59.5769 76H31.4231C22.3693 76 15 68.6331 15 59.5769V31.4231C15 22.3669 22.3693 15 31.4231 15H59.5769C68.6307 15 76 22.3669 76 31.4231V59.5769C76 68.6331 68.6307 76 59.5769 76ZM31.4231 19.6923C24.9547 19.6923 19.6923 24.9547 19.6923 31.4231V59.5769C19.6923 66.0453 24.9547 71.3077 31.4231 71.3077H59.5769C66.0453 71.3077 71.3077 66.0453 71.3077 59.5769V31.4231C71.3077 24.9547 66.0453 19.6923 59.5769 19.6923H31.4231Z" fill="#D504A7"/>
                </svg>
              </a>
              </div>
              <div style={{ display: "flex", fontSize: size == "mobile-large" ? "14px" : size === "tablet" ? "14px" : "18px",marginTop: size == "mobile-large" ? "3vh" : size === "tablet" ? "3vh" : "", color: "white", fontWeight: "bold",}}>scuppoficial@gmail.com</div>
            </div>
          </div>   

        </div>  
        <div style={{display: "flex", position: "relative", height: "40%", width: size === "mobile-large" ? "100%" : "40%", alignItems: "center", }}>
          <img
          style={{
            display: "flex",
            position: "absolute",
            left: size === "mobile-large" ? "5%" : "",
            zIndex: "1",
            width: size === "mobile-large" ? "70%"  : "80%",
            objectFit: "cover",
            }}
            src={logo}     
          /> 
        </div>
      </div>
    </div>
    </>
  );
}

export default ScuppDetails;
