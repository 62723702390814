import "../App.css";
import React, { useContext } from 'react';
import ScrollContext from './ScrollContext';
import { useInView } from "react-intersection-observer";
import stamp_001 from "../resources/STAMP_001.webp";
import stamp_002 from "../resources/STAMP_002.webp";



function Collections({ size }) {
  const { collectionsRef } = useContext(ScrollContext);
  const { ref: ref, inView: isVisible } = useInView({
    rootMargin: "0px"
  });
  return (
    <>
    <div ref={collectionsRef} style={{width: "100%", height: size === "mobile-large" ? "130vh" : "100vh", background: "linear-gradient(to left, black, black 25%, #1D081D 100%)", justifyContent: "center", alignItems: "center",}}>
      <div style={{display: "flex", flexDirection: size === "mobile-large" ? "column-reverse" : "row" , width: "100%", height: "100%", justifyContent: "center", alignItems: "center", alignSelf: "center", padding: size === "mobile-large" ? "5vh 0" : ""}}>
        <div style={{display: "flex",  height: "60%", width: size === "mobile-large" ? "100%" : "50%", justifyContent: "center" ,}}>
          <div style={{display: "flex", flexDirection: "column", height: "100%", width: "80%", justifyContent: size === "mobile-large" ? "start" : "center" }}>
            <h1 style={{color: "white", textTransform: "uppercase", fontWeight: "bold", fontSize: size == "mobile-large" ? "50px" : size == "tablet" ? "50px" : "72px",}}>Conheça novas figurinhas durante os eventos</h1>
            <div style={{fontSize: size == "mobile-large" ? "14px" : size == "tablet" ? "14px" : "18px", color: "#C9C9C9", lineHeight: "4vh", marginTop: "-5vh",}}>Aqui você entrará no nosso carro-chefe: A Scupp Experience!</div>   
            <div style={{fontSize: size == "mobile-large" ? "14px" : size == "tablet" ? "14px" : "18px", color: "#C9C9C9", lineHeight: "4vh", marginTop: "3vh",}}>Visualize os usuários online que estão na mesma festa que você, interaja via localização no aqui e agora, com diferentes formas de match gamificadas para aquela paquera específica que está na festa. Deixe a timidez de lado e entre nesse jogo divertido onde todos quebram o gelo de formas leves e rápidas, com filtros de acordo com o gosto de cada um e respeitando sempre a todos</div>   
          </div>   
        </div>  
        <div ref={ref} style={{display: "flex", position: "relative", height: size === "mobile-large" ? "40%" : size === "tablet" ? "50%" : "100%", width: size === "mobile-large" ? "100%" : "50%", opacity: isVisible ? 1 : 0, transition: "all 2s",}}>
          <img
          style={{
            display: "flex",
            position: "absolute",
            top: "10%",
            left: "10%",
            zIndex: "1",
            width: "45%",
            objectFit: "cover",
            }}
            src={stamp_001}     
          /> 
          <img
          style={{
            display: "flex",
            position: "absolute",
            top: "20%",
            left: "45%",
            zIndex: "2",
            width: "45%",
            objectFit: "cover",
            }}
            src={stamp_002}     
          /> 
        </div>
      </div>
    </div>
    </>
  );
}

export default Collections;
