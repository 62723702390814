import "../App.css";
import React, { useContext } from 'react';
import ScrollContext from './ScrollContext';
import { useInView } from "react-intersection-observer";
import phone from "../resources/PHONE_001.webp";
import background from "../resources/BACKGROUND_001.webp";
import events_001 from "../resources/EVENTS_001.webp";
import events_002 from "../resources/EVENTS_002.webp";
import events_003 from "../resources/EVENTS_003.webp";


function Events({ size }) {
    const { eventsRef } = useContext(ScrollContext);
    const { ref: eventRef1, inView: event1IsVisible } = useInView({
        rootMargin: "0px"
      });
    
      const { ref: eventRef2, inView: event2IsVisible } = useInView({
        rootMargin: "0px"
      });
    
      const { ref: eventRef3, inView: event3IsVisible } = useInView({
        rootMargin: "0px"
      });


  return (
    <>
        <img
            style={{
              display: "flex",
              position: "absolute",
              zIndex: "-2",
              width: "100%",
              height: size === "mobile-large" ? "250vh" : size === "tablet" ? "120vh" : "150vh",
              objectFit: "cover",
            }}
            src={background}
        />
        <div
            style={{
              display: "flex",
              position: "absolute",
              background: "rgba(0, 0, 0, 0.8)",
              zIndex: "-1",
              width: "100%",
              height: size === "mobile-large" ?  "250vh" : size === "tablet" ? "120vh" : "150vh",
            }}
        />  
        <div ref={eventsRef} style={{width: "100%",  height: size === "mobile-large" ?  "250vh" : size === "tablet" ? "120vh" : "150vh", justifyContent: "center", alignItems: "center", flexDirection: "column", overflow: "hidden",}}>
            <div style={{display: "flex",  flexDirection: size === "mobile-large" ? "column" : "row", width: "100%", height: "50%", justifyContent: "center", alignSelf: "center", alignItems: "center"}}>
                <div style={{ position: "relative", zIndex: "10", width: size === "mobile-large" ? "100%" : "50%", height: size === "mobile-large" ? "50%" : "100%", display: "flex", alignItems: "center", justifyContent: "center", top: size === "desktop-large" ? "5vh" : size === "tablet" ? "5vh" : size === "desktop-small" ? "-5vh" : "-5vh" }}>
                    <img
                        style={{
                            display: "flex",
                            position: "absolute",
                            width: size === "desktop-large" ? "25%" : size === "desktop-small" ? "25%" : size === "tablet" ? "35%" : size === "mobile-large" ? "35%" : "30%",
                            objectFit: "cover",
                        }}
                        src={phone}
                    />
                </div>

                <div style={{display: "flex",  height: size === "mobile-large"  ? "auto" : "50%", width:  size === "mobile-large" ? "100%" : "50%", alignItems: size === "mobile-large" ? "start" : "center", justifyContent: size === "mobile-large" ? "center" : "start"}}>
                    <div style={{display: "flex", flexDirection: "column", height: "100%", width: "75%", justifyContent: size === "mobile-large" ? "start" : "center",}}>
                        <h1 style={{color: "white", textTransform: "uppercase", fontWeight: "bold", margin: 0, fontSize: size == "mobile-large" || size === "tablet"  ? "50px" : "72px", }}>Veja os eventos mais bombados da sua cidade</h1>
                        <div style={{fontSize: size == "mobile-large" || size === "tablet"  ? "14px" : "18px", color: "#C9C9C9", lineHeight: "4vh", marginTop:  size == "mobile-large" ? "5vh" : ""}}>Cansado de procurar lugares e eventos interessantes para sair, e não ter uma garantia de que vai ser bacana? A Scupp te ajuda de forma divertida e prática a ter mais certeza de sua diversão, antes mesmo de entrar no Uber para se aventurar pela cidade.</div>
                    </div>
                </div>  
            </div>
            <div style={{display: "flex", flexDirection: size === "mobile-large" ? "column-reverse" : "row", width: "100%", height: "50%", justifyContent: "center", alignItems: size === "mobile-large" ? "center" : size === "tablet" ? "center" : "start", alignSelf: "center",}}>
                <div style={{display: "flex",  height: size === "mobile-large" ? "50%" : "50%", width: size === "mobile-large" ? "100%" : "50%", justifyContent: size == "mobile-large" ? "center" : "flex-end"}}>
                    <div style={{display: "flex", flexDirection: "column", height: "100%", width: "75%", justifyContent: size == "mobile-large" ? "center" : "flex-end"}}>
                        <h1 style={{color: "white", textTransform: "uppercase", fontWeight: "bold", margin: 0, fontSize: size == "mobile-large" || size === "tablet"  ? "50px" : "72px", }}>Mapa de calor real time</h1>
                        <div style={{fontSize: size == "mobile-large" || size === "tablet"  ? "14px" : "18px", color: "#C9C9C9", lineHeight: "4vh", marginTop:  size == "mobile-large" ? "5vh" : ""}}>Visualize de forma interativa e inovadora um mapa de calor da cidade, com todos os eventos mais bombados rolando no momento, ou vá para nosso catálogo de eventos para encontrar também eventos próximos a poucos cliques de distância</div>
                    </div>   
                </div>  
                <div style={{display: "flex", height: size === "mobile-large" ? "50%" : "50%", width:  size === "mobile-large" ? "100%" : "50%",top: size === "mobile-large" ? "0" : "-10%", position: "relative", alignItems: "center", justifyContent: "center"}}>
                    <img ref={eventRef1}
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: "5%",
                        left: "10%",
                        zIndex: "2",
                        opacity: event1IsVisible ? 1 : 0,
                        transform: event1IsVisible ? "translateX(0)": "translateX(+100%)",
                        transition: "all 1s",
                        width: size === "mobile-large" ? "65%" : "60%",
                        objectFit: "cover",
                        }}
                        src={events_001}     
                    />
                    <img ref={eventRef2}
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: "32%",
                        left: "17%",
                        zIndex: "3",
                        opacity: event2IsVisible ? 1 : 0,
                        transform: event2IsVisible ? "translateX(0)": "translateX(+100%)",
                        transition: "all 1s",
                        width: size === "mobile-large" ? "65%" : "60%",
                        objectFit: "cover",
                        }}
                        src={events_002}     
                    />
                    <img ref={eventRef3}
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: "59%",
                        left: "26.5%",
                        zIndex: "4",
                        opacity: event3IsVisible ? 1 : 0,
                        transform: event3IsVisible ? "translateX(0)": "translateX(+100%)",
                        transition: "all 1s",
                        width: size === "mobile-large" ? "65%" : "60%",
                        objectFit: "cover",
                        }}
                        src={events_003}     
                    />
                </div>
            </div> 
        </div>
    </>
  );
}

export default Events;
