import "../App.css";
import React, { useContext } from 'react';
import ScrollContext from './ScrollContext';
import { useInView } from "react-intersection-observer";
import Personalcoment from "./PersonalComent";
import AddReactionIcon from '@mui/icons-material/AddReaction';
import person_001 from "../resources/PERSON_001.webp";
import person_002 from "../resources/PERSON_002.webp";
import person_003 from "../resources/PERSON_003.webp";
import person_004 from "../resources/PERSON_004.webp";
import person_rolet_001 from "../resources/PERSON-ROLET_001.webp";
import person_rolet_002 from "../resources/PERSON-ROLET_002.webp";
import person_rolet_003 from "../resources/PERSON-ROLET_003.webp";
import person_rolet_004 from "../resources/PERSON-ROLET_004.webp";

function Social({ size }) {
    const { socialRef } = useContext(ScrollContext);
    const { ref: ref, inView: isVisible } = useInView({
      });

  return (
    <>
    <div ref={socialRef} style={{width: "100%", height: size === "mobile-large" ? "140vh" : size === "tablet" ? "200vh" : "100vh", background: "linear-gradient(to left, black, black 25%, #1D1008 100%)", justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: size === "mobile-large" ? "column" : size === "tablet" ? "column" : "row", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", alignSelf: "center"}}>
            <div style={{display: "flex", flexDirection: "column", height: size === "mobile-large" ? "50%" : "100%", width: size === "mobile-large" ? "100%" : size === "tablet" ? "100%" : "60%", alignItems: "center", justifyContent: "center"}}>
                <Personalcoment size={size} posc={1} user={"@milenamk"} coment={"Preparem-se para rolar de rir e compartilhar aqueles memes que vão ficar na memória"} emoji={AddReactionIcon} img={person_001} />
                <Personalcoment size={size} posc={2} user={"@sarrajin"} coment={"Quem mais está animado para este evento?"} emoji={AddReactionIcon} img={person_002} />
                <div style={{display: "flex", flexDirection: "row", height: size === "mobile-large" ? "60%" : "50%", width: size === "mobile-large" ? "80%" :  "100%" }}>
                    <div ref={ref} style={{display: "flex",position: "relative", height: "100%" , width: "70%", alignItems: "center", justifyContent: "start", left: "0", transform: isVisible ? "translateX(0)": "translateX(+100%)", opacity: isVisible ? 1 : 0, transition: "all 1s",}}>
                        <img
                            style={{display: "flex", position: "absolute", left: "-15%", zIndex: "1", width: "40%",objectFit: "fill",}}
                            src={person_rolet_004}     
                        />
                        <img
                            style={{display: "flex", position: "absolute", left: "10%", zIndex: "2", width: "40%",objectFit: "fill",}}
                            src={person_rolet_003}     
                        />
                        <img
                            style={{display: "flex", position: "absolute", left: "35%", zIndex: "3", width: "40%",objectFit: "fill",}}
                            src={person_rolet_002}     
                        />
                        <img
                            style={{display: "flex", position: "absolute", left: "60%", zIndex: "4", width: "40%",objectFit: "fill",}}
                            src={person_rolet_001}     
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "column", width: "30%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                        <h1  style={{display: "flex", position: "relative", width: "90%", height: "10%", color: "#FF6F4D", textTransform: "uppercase", fontWeight: "bold", justifyContent: "center", fontSize: size === "tablet" ? "6em" : "4em"}}>+1000</h1>
                        <div style={{display: "flex", position: "relative", width: "auto", height: "auto", padding: size === "mobile-large" ? "5px" : "2% 6%",fontWeight: "bold", backgroundColor: "#FF6F4D", color: "#1E1E1E", fontSize: "0.8em", marginTop: size === "mobile-large" ? "5px" : "5%", justifyContent: "center", borderRadius: "30px"}}>✔ Confirmed</div>
                    </div>
                </div>
                {size != "mobile-large" &&                 
                <>
                    <Personalcoment size={size} posc={3} user={"@sbigmac"} coment={"Hora de colocar o sorriso no rosto, os pés na pista de dança e curtir cada momento desta festa inesquecivel!!!"} emoji={AddReactionIcon} img={person_003} />
                    <Personalcoment size={size} posc={4} user={"@gamemastergm"} coment={"Estou pronto para curtir essa festa até o amanhecer! ^-^"} emoji={AddReactionIcon} img={person_004} />
                </>}

            </div>
            <div style={{display: "flex",  height: size === "mobile-large" ? "60%" : size === "tablet" ? "20%" : "100%", width: size === "mobile-large" ? "100%" : size === "tablet" ? "100%" : "40%", justifyContent: size === "mobile-large" ?  "center" : size === "tablet" ? "center" : "start"}}>
                <div style={{display: "flex", flexDirection: size === "mobile-large" ? "column" : size === "tablet" ? "row" : "column", height: "100%", width: size === "mobile-large" ? "90%" : size === "tablet" ? "90%" : "80%", justifyContent: "center", alignItems: size === "tablet" ? "center" : "", }}>
                    <h1 style={{color: "white", textTransform: "uppercase", fontWeight: "bold", margin: 0, fontSize: size == "mobile-large" ? "50px" : size === "tablet" ? "50px" : "72px", }}>Saiba quem já confirmou presença no evento</h1>
                    <div style={{fontSize: size == "mobile-large" ? "14px" : size === "tablet" ? "14px" : "18px", color: "#C9C9C9", lineHeight: "4vh", marginLeft: size === "tablet" ? "10%" : "",}}>Veja quantas e quais pessoas já confirmaram presença em cada festa da cidade, e deixe outros usuários saberem se você também vai ou não. Participe dessa inovadora comunidade de festeiros!</div>      
                </div>   
            </div> 
            {size === "mobile-large" &&                 
                <>
                    <Personalcoment size={size} posc={3} user={"@sbigmac"} coment={"Hora de colocar o sorriso no rosto, os pés na pista de dança e curtir cada momento desta festa inesquecivel!!!"} emoji={AddReactionIcon} img={person_003} />
                    <Personalcoment size={size} posc={4} user={"@gamemastergm"} coment={'Estou pronto para curtir essa festa até o amanhecer! ^-^'} emoji={AddReactionIcon} img={person_004} />
                </>} 
        </div>
    </div>
    </>
  );
}

export default Social;
