import React from 'react';
import '../App.css';
import Navybar from './Navybar';
import Home from './Home';
import Events from './Events';
import Social from './Social';
import Collections from './Collections';
import ScuppDetails from './ScuppDetails';
import { ScrollProvider } from './ScrollContext'; // Importe o provedor

function MainPage({ screenClass }) {
  return (
    <ScrollProvider>
      {screenClass && 
        <div style={{overflow: "hidden"}}>
          <Navybar size={screenClass} />
          <Home size={screenClass} />
          <Events size={screenClass} />
          <Social size={screenClass} />
          <Collections size={screenClass} />
          <ScuppDetails size={screenClass} />
        </div>
      } 
      {console.log(screenClass)}
    </ScrollProvider>
  );
}

export default MainPage;
