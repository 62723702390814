import React, { createContext, useRef } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const eventsRef = useRef(null);
  const socialRef = useRef(null);
  const collectionsRef = useRef(null);
  const scuppDetailsRef = useRef(null);

  const contextValue = {
    eventsRef,
    socialRef,
    collectionsRef,
    scuppDetailsRef,
  };

  return (
    <ScrollContext.Provider value={contextValue}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollContext;
